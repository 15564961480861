import ApiService from '../ApiService';

class AuthService extends ApiService {

    async login(userData: { username: string, password: string }, headers = {}): Promise<{ data: string }> {
        return this.post(`/auth`, userData, false, headers);
    }

    async check(): Promise<{ data: string }> {
        return this.get(`/auth`);
    }
}

export default AuthService;
