import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PrivateRoute from './PrivateRoute';


const Contracts = lazy(() => import('./pages/contracts/ContractsList'));
const Contract = lazy(() => import('./pages/contracts/ContractForm'));
const ContractView = lazy(() => import('./pages/contracts/ContractView'));
const Clients = lazy(() => import('./pages/clients/ClientsList'));
const Client = lazy(() => import('./pages/clients/ClientForm'));
const ClientView = lazy(() => import('./pages/clients/ClientView'));
const Products = lazy(() => import('./pages/products/ProductsList'));
const Product = lazy(() => import('./pages/products/ProductForm'));
const ProductView = lazy(() => import('./pages/products/ProductView'));
const Sales = lazy(() => import('./pages/sale-orders/SalesOrdersList'));
const Sale = lazy(() => import('./pages/sale-orders/SaleOrderForm'));
const SaleView = lazy(() => import('./pages/sale-orders/SaleOrderView'));
const Billings = lazy(() => import('./pages/billings/BillingsList'));
const Login = lazy(() => import('./pages/Login'));
const Home = lazy(() => import('./pages/Home'));

const App: React.FC = () => {
    return (
        <Router>
            <Suspense fallback={<div>Carregando...</div>}>
                <ToastContainer />
                <Routes>
                    <Route path="/:slug/login" element={<Login />} />
                    <Route path="/:slug/*" element={<PrivateRoute> <Home /> </PrivateRoute>} />
                    <Route path="/:slug/registers/clients" element={<PrivateRoute> <Clients /> </PrivateRoute>} />
                    <Route path="/:slug/registers/client" element={<PrivateRoute> <Client /> </PrivateRoute>} />
                    <Route path="/:slug/registers/client/:id" element={<PrivateRoute> <Client /> </PrivateRoute>} />
                    <Route path="/:slug/registers/client/view/:id" element={<PrivateRoute> <ClientView /> </PrivateRoute>} />
                    <Route path="/:slug/registers/products" element={<PrivateRoute> <Products /> </PrivateRoute>} />
                    <Route path="/:slug/registers/product" element={<PrivateRoute> <Product /> </PrivateRoute>} />
                    <Route path="/:slug/registers/product/:id" element={<PrivateRoute> <Product /> </PrivateRoute>} />
                    <Route path="/:slug/registers/product/view/:id" element={<PrivateRoute> <ProductView /> </PrivateRoute>} />
                    <Route path="/:slug/sales/sale-order" element={<PrivateRoute> <Sale /> </PrivateRoute>} />
                    <Route path="/:slug/sales/sale-order/:id" element={<PrivateRoute> <Sale /> </PrivateRoute>} />
                    <Route path="/:slug/sales/sale-order/view/:id" element={<PrivateRoute> <SaleView /> </PrivateRoute>} />
                    <Route path="/:slug/sales/sale-orders" element={<PrivateRoute> <Sales /> </PrivateRoute>} />
                    <Route path="/:slug/sales/billings" element={<PrivateRoute> <Billings /> </PrivateRoute>} />
                    <Route path="/:slug/services/contracts" element={<PrivateRoute> <Contracts /> </PrivateRoute>} />
                    <Route path="/:slug/services/contract" element={<PrivateRoute> <Contract /> </PrivateRoute>} />
                    <Route path="/:slug/services/contract/:id" element={<PrivateRoute> <Contract /> </PrivateRoute>} />
                    <Route path="/:slug/services/contract/view/:id" element={<PrivateRoute> <ContractView /> </PrivateRoute>} />
                </Routes>
            </Suspense>
        </Router>
    );
};

export default App;
