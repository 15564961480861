import React, { Component } from 'react';
import { Navigate } from 'react-router-dom';
import AuthService from './services/auth/AuthService';

interface PrivateRouteProps {
  children: React.ReactNode;
}

interface PrivateRouteState {
  isAuthenticated: boolean | null;
  slug: string | null;
}

class PrivateRoute extends Component<PrivateRouteProps, PrivateRouteState> {
  constructor(props: PrivateRouteProps) {
    super(props);
    this.state = {
      isAuthenticated: null,
      slug: localStorage.getItem('slug'),
    };
  }

  async componentDidMount() {
    try {
      await new AuthService().check();
      this.setState({ isAuthenticated: true });
    } catch (error) {
      this.setState({ isAuthenticated: false });
    }
  }

  render() {
    const { isAuthenticated, slug } = this.state;

    if (isAuthenticated === false) {
      return <Navigate to={`/${slug}/login`} />;
    }

    return <>{this.props.children}</>;
  }
}

export default PrivateRoute;
