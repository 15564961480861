import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#ff0000', // Defina a cor principal
    },
    secondary: {
      main: '#00ff00', // Defina a cor secundária
    },
  },
});

export default theme;
