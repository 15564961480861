import React from 'react';
import ReactDOM from 'react-dom/client';
import { ThemeProvider } from '@mui/material/styles';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import theme from './theme/ThemeProvider';
import CompanyService from './services/companies/CompanyService';


// Função para buscar informações do projeto e configurar o tema
async function fetchProjectAndRenderApp() {
  try {
    // Obtenha o slug da URL, por exemplo, usando window.location.pathname
    const slug = window.location.pathname.split('/');
    if (slug.length > 1) {
      // Faça uma chamada à API para obter informações do projeto com base no slug
      const company = await new CompanyService().slug(slug[1]);

      if (company) {
        localStorage.setItem('tenant', company.data.id)
        localStorage.setItem('slug', company.data.slug)
        localStorage.setItem('company', JSON.stringify(company.data))

        // Configure o tema com base nas informações do projeto
        theme.palette.primary.main = company.data.primary_color;
        theme.palette.secondary.main = company.data.secondary_color;
  
        // Renderize o aplicativo após obter as informações do projeto e configurar o tema
        ReactDOM.createRoot(
          document.getElementById('root') as HTMLElement
        ).render(
          <React.StrictMode>
            <ThemeProvider theme={theme}>
              <App />
            </ThemeProvider>
          </React.StrictMode>
        );
        reportWebVitals();
      }
    }
  } catch (error) {
    console.error('Erro ao buscar informações do projeto:', error);
    // Lide com erros de forma apropriada
  }
}

// Chame a função para buscar informações do projeto e renderizar o aplicativo
fetchProjectAndRenderApp();

