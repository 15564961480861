import axios, { AxiosResponse } from 'axios';

class ApiService {
    private baseUrl: string;
    private axiosInstance: any;
    private token: string | null;

    constructor() {
        this.baseUrl = process.env.REACT_APP_API_BASE_URL || '';
        this.axiosInstance = axios.create({
            baseURL: this.baseUrl,
        });
        this.token = localStorage.getItem('access_token');;
        this.setupInterceptors();
    }

    private async createHeaders(isPrivate: boolean = false, headers = {}) {
        const custom: Record<string, string> = headers;

        if (isPrivate && this.token) {
            custom['Authorization'] = `Bearer ${this.token}`;
        }

        return custom;
    }

    // Métodos de solicitação GET, POST, PUT, DELETE usando axiosInstance
    async get<T>(endpoint: string, isPrivate: boolean = true, headers = {}, responseType = 'json'): Promise<T> {
        try {
            const config = {
                headers: await this.createHeaders(isPrivate, headers),
                responseType
            };
            const response: AxiosResponse<T> = await this.axiosInstance.get(endpoint, config);
            return response.data;
        } catch (error: any) {
            throw error;
        }
    }

    async post<T>(endpoint: string, data: any, isPrivate: boolean = true, headers = {}): Promise<T> {
        try {
            const config = {
                headers: await this.createHeaders(isPrivate, headers),
            };
            const response: AxiosResponse<T> = await this.axiosInstance.post(endpoint, data, config);
            return response.data;
        } catch (error: any) {
            throw error;
        }
    }

    async put<T>(endpoint: string, data: any, isPrivate: boolean = true, headers = {}): Promise<T> {
        try {
            const config = {
                headers: await this.createHeaders(isPrivate, headers),
            };
            const response: AxiosResponse<T> = await this.axiosInstance.put(endpoint, data, config);
            return response.data;
        } catch (error: any) {
            throw error;
        }
    }

    async delete(endpoint: string, isPrivate: boolean = true): Promise<void> {
        try {
            const config = {
                headers: await this.createHeaders(isPrivate),
            };
            await this.axiosInstance.delete(endpoint, config);
        } catch (error: any) {
            throw error;
        }
    }

    private setupInterceptors() {
        // Configurar interceptor de resposta para capturar erros
        this.axiosInstance.interceptors.response.use(
            (response: AxiosResponse) => response,
            (error: any) => {
                return Promise.reject(error.response?.data); // Retorna o erro para tratamento posterior
            }
        );
    }
}

export default ApiService;
